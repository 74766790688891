
<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky">
    <div class="container">
      <!-- Logo container-->
      <div>
        <a class="logo" [ngxScrollTo]="'#home'">
          <img src="assets/images/logo-dark.png" height="24" alt="">
        </a>
      </div>
      <div class="menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <a class="navbar-toggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </div>
      </div>
  
      <!-- <div id="navigation">      
        <ul class="navigation-menu">
          <li class="has-submenu" [ngClass]="{'active':currentSection === 'home'}">
            <a href="javascript: void(0);" [ngxScrollTo]="'#home'">Home</a>
          </li>
          <li class="has-submenu" [ngClass]="{'active':currentSection === 'service'}">
            <a href="javascript: void(0);" [ngxScrollTo]="'#service'">Feature</a>
          </li>
          <li class="has-submenu" [ngClass]="{'active':currentSection === 'testimonial'}">
            <a href="javascript: void(0);" [ngxScrollTo]="'#testimonial'">Review</a>
          </li>
          <li class="has-submenu" [ngClass]="{'active':currentSection === 'pricing'}">
            <a href="javascript: void(0);" [ngxScrollTo]="'#pricing'">Price</a>
          </li>
          <li class="has-submenu" [ngClass]="{'active':currentSection === 'contact'}">
            <a href="javascript: void(0);" [ngxScrollTo]="'#contact'">Contact</a>
          </li>
        </ul>    
      </div> -->
    </div>
    <!--end container-->
  </header>
<!-- Hero Start -->
<section class="bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center" *ngIf="guia === null">
                <div class="pages-heading" style="margin-top: 130px;margin-bottom: 100px;">
                    <form class="form" action="#">
                        <div class="header">
                            <h1>Rastrea tu envío</h1>
                        </div>
                        <input #guideM id="guideM" class="input" style="margin-left: 10px; margin-top: 10px;"
                            type="text" placeholder="Número de guía">
                        <button class="button" style="margin-left: 10px; margin-top: 10px;" type="submit"
                            (click)="buscar(guideM.value)">Buscar</button>
                    </form>
                </div>
            </div>
            <div class="col-lg-12 text-center" *ngIf="guia !== null && cargando === true ">
                <div class="pages-heading" style="margin-top: 280px;margin-bottom: 100px;">
                    <form class="form" action="#">
                        <app-loader *ngIf="cargando" [id]="'first'"></app-loader>
                    </form>
                </div>
            </div>


            <div class="col-lg-12 text-center" *ngIf="guia !== null && cargando === false ">
                <div class="pages-heading" style="margin-top: 130px;">
                    <div class="header">
                        <h1>Rastrea tu envío</h1>
                    </div>

                    <div class="content">
                        <div class="content1">
                            <h2>Número guía: {{datos?.guia}}</h2>
                        </div>
                        <div class="row content2">
                            <div class="content2-header1 col-xs-12 col-sm-4">
                                <p>Ciudad : <span>{{datos?.ciudad}}</span></p>
                            </div>
                            <div class="content2-header1 col-xs-12 col-sm-3">
                                <p>Estado: <span>{{estadoFinal}}</span></p>
                            </div>
                            <div class="content2-header1 col-xs-12 col-sm-5">
                                <p><span *ngIf="entr">Fecha: </span><span *ngIf="!entr">Entrega esperada:
                                    </span><span>{{datos?.fechaEntrega}}</span></p>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="row content3">
                            <div class="shipment">
                                <div class="confirm" [ngClass]="estadoFinal == 'StatusGuides.generada' ? 'active' : ''">
                                    <div class="imgcircle" [ngClass]="gen ? 'verde' : 'gris'">
                                        <img src="assets/images/confirm.png" alt="confirm order">
                                    </div>
                                    <span class="line" [ngClass]="gen ? 'verde' : 'gris'"></span>
                                    <p class="status">Generada</p>
                                </div>
                                <div class="confirm" [ngClass]="estadoFinal == 'StatusGuides.confirmado' ? 'active' : ''">
                                    <div class="imgcircle" [ngClass]="conf ? 'verde' : 'gris'">
                                        <img src="assets/images/confirm.png" alt="confirm order">
                                    </div>
                                    <span class="line" [ngClass]="conf ? 'verde' : 'gris'"></span>
                                    <p class="status">Confirmada</p>
                                </div>
                                <ng-container *ngIf="anul">
                                    <div class="process" [ngClass]="estadoFinal == 'StatusGuides.cancelada' ? 'active' : ''">
                                        <div class="imgcircle" [ngClass]="anul ? 'verde' : 'gris'">
                                            <img src="assets/images/process.png" alt="process order">
                                        </div>
                                        <span class="line" [ngClass]="anul ? 'verde' : 'gris'"></span>
                                        <p class="status">Cancelada</p>
                                    </div>
                                </ng-container> 
                                
                                <ng-container *ngIf="procsi tiagen">

                                <div class="process" [ngClass]="estadoFinal == 'StatusGuides.procesado' ? 'active' : ''">
                                    <div class="imgcircle" [ngClass]="proc ? 'verde' : 'gris'">
                                        <img src="assets/images/process.png" alt="process order">
                                    </div>
                                    <span class="line" [ngClass]="proc ? 'verde' : 'gris'"></span>
                                    <p class="status">Procesada</p>
                                </div>

                            </ng-container> 

                                <ng-container *ngIf="asig">
                                    <div class="process" [ngClass]="estadoFinal == 'StatusGuides.asignada' ? 'active' : ''">
                                        <div class="imgcircle" [ngClass]="asig ? 'verde' : 'gris'">
                                            <img src="assets/images/process.png" alt="process order">
                                        </div>
                                        <span class="line" [ngClass]="asig ? 'verde' : 'gris'"></span>
                                        <p class="status">Asignada</p>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="nove">
                                    <div class="quality" [ngClass]="estadoFinal == 'StatusGuides.novedad' ? 'active' : ''">
                                        <div class="imgcircle" [ngClass]="nove ? 'verde' : 'gris'">
                                            <img src="assets/images/quality.png" alt="quality check">
                                        </div>
                                        <span class="line" [ngClass]="nove ? 'verde' : 'gris'"></span>
                                        <p class="status">Novedad</p>
                                    </div>
                                </ng-container> 

                                <ng-container *ngIf="sol">
                                    <div class="process" [ngClass]="estadoFinal == 'StatusGuides.solucionado' ? 'active' : ''">
                                        <div class="imgcircle" [ngClass]="sol ? 'verde' : 'gris'">
                                            <img src="assets/images/process.png" alt="process order">
                                        </div>
                                        <span class="line" [ngClass]="sol ? 'verde' : 'gris'"></span>
                                        <p class="status">Solucionada</p>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="tran">
                                    <div class="dispatch" [ngClass]="estadoFinal == 'StatusGuides.reparto' ? 'active' : ''">
                                        <div class="imgcircle" [ngClass]="tran ? 'verde' : 'gris'">
                                            <img src="assets/images/dispatch.png" alt="dispatch product">
                                        </div>
                                        <span class="line" [ngClass]="tran ? 'verde' : 'gris'"></span>
                                        <p class="status">En reparto</p>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="dev">
                                    <div class="quality" [ngClass]="estadoFinal == 'StatusGuides.devolucion' ? 'active' : ''">
                                        <div class="imgcircle" [ngClass]="dev ? 'verde' : 'rojo'">
                                            <img src="assets/images/quality.png" alt="quality check">
                                        </div>
                                        <!-- <span class="line" [ngClass]="dev ? 'verde' : 'rojo'"></span> -->
                                        <p class="status">Devolución</p>
                                    </div>
                                </ng-container> 
                                                              
                                <ng-container *ngIf="devConfir">
                                    <div class="quality" [ngClass]="estadoFinal == 'StatusGuides.devolucionConfirmada' ? 'active' : ''">
                                        <div class="imgcircle" [ngClass]="devConfir ? 'verde' : 'rojo'">
                                            <img src="assets/images/quality.png" alt="quality check">
                                        </div>
                                        <!-- <span class="line" [ngClass]="dev ? 'verde' : 'rojo'"></span> -->
                                        <p class="status">Devolucion confirmada</p>
                                    </div>
                                </ng-container> 
                                
                                <ng-container *ngIf="entr">
                                    <div class="delivery" [ngClass]="estadoFinal == 'StatusGuides.entregada' ? 'active' : ''">
                                        <div class="imgcircle" [ngClass]="entr ? 'verde' : 'gris'">
                                            <img src="assets/images/delivery.png" alt="delivery">
                                        </div>
                                        <p class="status">Entregada</p>
                                    </div>
                                </ng-container>                                  
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 text-center">
                        <div class="pages-heading">
                            <form class="form" action="#">
                                <button class="button" style="margin-left: 10px; margin-top: 10px;" type="submit"
                                    (click)="buscarMas()">Buscar Mas</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div> <!--end col-->
        </div><!--end row-->
        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Swayp</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Tracking</a></li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->
<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->
<!-- Start Store -->
<section class="section p-1 mt-4" *ngIf="guia !== null && cargando === false && verTienda === true">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="card shadow border-0 rounded" style="background-color: #dbb356;">
                    <div class="card-body">
                        <h5 class="card-title">TU COMPRA</h5>
                        <br>
                        <div class="row">
                            <div class="col-md-8">
                                <p>Desde <strong>{{tienda}}</strong>, queremos agradecerte por tu compra. Nos
                                    enorgullece
                                    ofrecerte
                                    una
                                    experiencia de compra satisfactoria y esperamos que hayas encontrado justo lo que
                                    estabas
                                    buscando.
                                </p>
                                <p>
                                    Recuerda que en <strong>{{tienda}}</strong> siempre estamos trabajando para
                                    ofrecerte
                                    los mejores
                                    productos y
                                    servicios. Si necesitas ayuda con tu entrega o con los productos, no dudes
                                    en
                                    escribirnos a nuestro Whatsapp.
                                </p>
                                <p>
                                    Te invitamos a explorar nuestra amplia selección de productos y esperamos verte de
                                    nuevo
                                    pronto en <strong>{{tienda}}</strong>. ¡Gracias por tu confianza!
                                </p>
                            </div>
                            <div class="col-md-4 container4">
                                <div class="circle">
                                    <img src="./../../../../assets/images/favicon.png" alt="swayp" width="150px"
                                        height="150px" style="margin: 25px;">
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Store -->

<!-- Start Terms & Conditions -->
<section class="section p-1 mt-4">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <h5 class="card-title">Condiciones del servicio</h5>
                        <br>

                        <p class="text-muted">Horario de entrega: Los paquetes se entregarán de lunes a viernes
                            entre
                            las 7:00 a.m. y las 5:00 p.m. No se realizarán entregas en fines de semana ni días
                            festivos.
                        </p>

                        <p class="text-muted">Verificación de la dirección: La empresa se asegurará de que la
                            dirección
                            de entrega proporcionada por el cliente sea correcta antes de realizar la entrega.
                        </p>
                        <p class="text-muted">Requisitos de firma: La entrega sólo se considerará completada
                            cuando el
                            destinatario haya firmado un recibo de entrega.
                        </p>
                        <p class="text-muted">Devolución de paquetes no entregados: Si el destinatario no está
                            disponible para recibir el paquete en el momento de la entrega, la empresa intentará
                            entregarlo en una fecha posterior acordada con el destinatario. Si después de 3
                            intentos de
                            entrega fallidos, el paquete será devuelto al remitente.
                        </p>
                        <p class="text-muted">Responsabilidad por paquetes dañados o perdidos: La empresa
                            garantiza la
                            integridad del paquete desde el momento de la recogida hasta la entrega. En caso de
                            que el
                            paquete se dañe o se pierda durante el transporte, la empresa asumirá la
                            responsabilidad y
                            proporcionará un reemplazo o un reembolso a su discreción.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<br>
<br>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->