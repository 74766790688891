<app-header></app-header>
<!-- <header id="topnav" class="defaultscroll sticky">
  <div class="container">
  
    <div>
      <a class="logo" [ngxScrollTo]="'#home'">
        <img src="assets/images/logo-dark.png" height="24" alt="">
      </a>
    </div>
    <div class="menu-extras">
      <div class="menu-item">
       
        <a class="navbar-toggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
       
      </div>
    </div>
    

    <div id="navigation">      
      <ul class="navigation-menu">
        <li class="has-submenu" [ngClass]="{'active':currentSection === 'home'}">
          <a href="javascript: void(0);" [ngxScrollTo]="'#home'">Home</a>
        </li>
        <li class="has-submenu" [ngClass]="{'active':currentSection === 'service'}">
          <a href="javascript: void(0);" [ngxScrollTo]="'#service'">Feature</a>
        </li>
        <li class="has-submenu" [ngClass]="{'active':currentSection === 'testimonial'}">
          <a href="javascript: void(0);" [ngxScrollTo]="'#testimonial'">Review</a>
        </li>
        <li class="has-submenu" [ngClass]="{'active':currentSection === 'pricing'}">
          <a href="javascript: void(0);" [ngxScrollTo]="'#pricing'">Price</a>
        </li>
        <li class="has-submenu" [ngClass]="{'active':currentSection === 'contact'}">
          <a href="javascript: void(0);" [ngxScrollTo]="'#contact'">Contact</a>
        </li>
      </ul>    
    </div>
  </div>
  
</header> -->
<!-- Hero Start -->
<section class="d-table w-100" id="home">
  <div class="container" style="margin-top: 130px; margin-bottom: 100px;">
    <div class="row mt-5 align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="title-heading">
          <h1 class="heading mb-2">¿Problemas con tus envíos, novedades y recaudos?</h1>
          <!-- <p class="para-desc text-muted">Iniciamos en el primer trimestre del 2023.</p> -->
          <!-- <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
            <a href="javascript:void(0)" class="btn btn-outline-primary mt-2"><i class="uil uil-google-play"></i> Play
              Store</a>
          </div> -->
          <p class="mt-4 para-desc text-muted">Somos la última milla todo en uno, recogemos, entregamos y recaudamos los
            pagos de tu comercio. Mientras tú vendes nosotros entregamos.</p>
          <!-- <ul class="para-desc text-muted">
            <li>Dropshipping</li>
            <li>Last Mile</li>
            <li>Fulfillment</li>
          </ul> -->
        </div>
        <a target="_blank" href="https://api.whatsapp.com/send?phone=573024144473&text=Hola%20SWAYP"
          class="btn btn-primary">Escribenos Ahora</a>

      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="text-md-end text-center ms-lg-4">
          <img src="assets/images/banner-inicial.png" class="img-fluid" alt="">
        </div>
      </div>
    </div>

    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Features Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h2 class="heading">Imagina tu logística así</h2>
        </div>
      </div>
      <div class="col-lg-12 col-md-12">
        <div class="row mt-4 pt-2">
          <div class="col-md-6 col-12">

            <!-- <div class="col-md-6 col-12">
            <div class="d-flex features feature-primary pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <i-feather name="smartphone" class="fea icon-ex-md text-primary"></i-feather>
              </div>
              <div class="flex-1">
                <h4 class="title">Control de inventarios</h4>
                <p class="text-muted para mb-0">Controla de forma eficiente y oportuna todo el inventario de tu negocio,
                  gracias a nuestra interfaz amigable y fácil de usar.</p>
              </div>
            </div>
          </div> -->

            <div class="d-flex features feature-primary pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <img class="icon-image-srv" src="../../../../assets/images/mismodia.png" alt="">
                <!-- <i-feather name="monitor" class="fea icon-ex-md text-primary"></i-feather> -->
              </div>
              <div class="flex-1">
                <h4 class="title title-section">Entregamos tus pedidos el mismo día</h4>
                <p class="text-muted para mb-0">Ofrece a tu cliente la posibilidad de entregar tu pedido el mismo día,
                  contamos con domiciliarios comprometidos y dispuestos a brindar la mejor experiencia a
                  tu cliente.</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="d-flex features feature-primary pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <img class="icon-image-srv" src="../../../../assets/images/devoluciones.png" alt="">
                <!-- <i-feather name="feather" class="fea icon-ex-md text-primary"></i-feather> -->
              </div>
              <div class="flex-1">
                <h4 class="title title-section">Confirmación de pedidos</h4>
                <p class="text-muted para mb-0">Gracias a Nuestro sistema podemos confirmar tus pedidos para que no
                  pierdas tiempo y dinero.</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="d-flex features feature-primary pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <img class="icon-image-srv" src="../../../../assets/images/flujocaja.png" alt="">
                <!-- <i-feather name="eye" class="fea icon-ex-md text-primary"></i-feather> -->
              </div>
              <div class="flex-1">
                <h4 class="title title-section">Liquidación de guías en tiempo real</h4>
                <p class="text-muted para mb-0">Entendemos que el dinero es parte importante de tu comercio electrónico,
                  por eso hemos integrado una billetera con pagos al instante, ahora no hay que esperar para recaudar tu
                  dinero.</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="d-flex features feature-primary pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <img class="icon-image-srv" src="../../../../assets/images/devoluciones.png" alt="">
                <!-- <i-feather name="feather" class="fea icon-ex-md text-primary"></i-feather> -->
              </div>
              <div class="flex-1">
                <h4 class="title title-section">Confirmación de pedidos</h4>
                <p class="text-muted para mb-0">Gracias a Nuestro sistema podemos confirmar tus pedidos para que no
                  pierdas tiempo y dinero.</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="d-flex features feature-primary pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <img class="icon-image-srv" src="../../../../assets/images/analitica.png" alt="">
                <!-- <i-feather name="user-check" class="fea icon-ex-md text-primary"></i-feather> -->
              </div>
              <div class="flex-1">
                <h4 class="title title-section">Analíticas en tiempo real</h4>
                <p class="text-muted para mb-0">Nuestro software recopila y almacena datos relevantes, los cuales nos
                  sirven para tomar las mejores decisiones estratégicas que ayuden a impulsar tus ventas.</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="d-flex features feature-primary pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <img class="icon-image-srv" src="../../../../assets/images/direcciones.png" alt="">
                <!-- <i-feather name="smartphone" class="fea icon-ex-md text-primary"></i-feather> -->
              </div>
              <div class="flex-1">
                <h4 class="title title-section">Reconocimiento inteligente de direcciones</h4>
                <p class="text-muted para mb-0">Te informamos cuando una dirección esta errónea, esto te ayuda a reducir
                  la probabilidad de tener novedades y devoluciones.</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="d-flex features feature-primary pt-4 pb-4">
              <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                <img class="icon-image-srv" src="../../../../assets/images/Integraciones.png" alt="">
                <!-- <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather> -->
              </div>
              <div class="flex-1">
                <h4 class="title title-section">Integraciones</h4>
                <p class="text-muted para mb-0">Subir pedidos de forma manual es parte del pasado, nuestro sistema se
                  integra con plataformas como Shopify, Woocommerce entre otros para automatizar este proceso.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <a target="_blank" href="https://api.whatsapp.com/send?phone=573024144473&text=Hola%20SWAYP"
          class="btn-full btn btn-primary text-center">Escribenos Ahora</a>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</section>

<section class="d-table w-100" id="home">
  <div class="container" style="margin-top: 130px; margin-bottom: 100px;">
    <div class="row mt-5 align-items-center">
      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0 pb-sm-10 pb-5">
        <div class="text-md-end text-center ms-lg-4">
          <img src="../../../../assets/images/banner-billetra.png" class="img-fluid" alt="">
        </div>
      </div>
      <div class="col-lg-1 col-md-1"></div>
      <div class="col-lg-6 col-md-6">
        <div class="title-heading">
          <h1 class="heading mb-2">Tu efectivo disponible al instante con la billetera virtual de SWAYP</h1>
          <!-- <p class="para-desc text-muted">Iniciamos en el primer trimestre del 2023.</p> -->
          <!-- <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
            <a href="javascript:void(0)" class="btn btn-outline-primary mt-2"><i class="uil uil-google-play"></i> Play
              Store</a>
          </div> -->
          <p class="mt-4 para-desc text-muted">En el mundo del ecommerce, cada segundo cuenta. Con la billetera de
            SWAYP, la liquidación al instante se convierte en tu mejor aliado para mantener un flujo de caja sólido y
            eficiente. ¡Aprovecha cada oportunidad al máximo!</p>
          <!-- <ul class="para-desc text-muted">
            <li>Dropshipping</li>
            <li>Last Mile</li>
            <li>Fulfillment</li>
          </ul> -->
        </div>
        <a target="_blank" href="https://api.whatsapp.com/send?phone=573024144473&text=Hola%20SWAYP"
          class="btn btn-primary">Escribenos Ahora</a>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<section>

  <section class="d-table w-100" id="home">
    <div class="container" style="margin-top: 35px; margin-bottom: 100px;">
      <div class="row mt-5 align-items-center">
        <div class="col-lg-1 col-md-1"></div>
        <div class="col-lg-6 col-md-6">
          <div class="title-heading">
            <h1 class="heading mb-2">Transformando entregas en experiencias</h1>
            <!-- <p class="para-desc text-muted">Iniciamos en el primer trimestre del 2023.</p> -->
            <!-- <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
            <a href="javascript:void(0)" class="btn btn-outline-primary mt-2"><i class="uil uil-google-play"></i> Play
              Store</a>
          </div> -->
            <p class="mt-4 para-desc text-muted">En cada pedido, cuidamos más que solo productos. Cuidamos sonrisas de
              satisfacción.
              Porque sabemos que enviar tus productos en perfecto estado no es solo un detalle, es nuestra promesa de
              calidad y atención. Tu felicidad es nuestro objetivo. </p>
            <!-- <ul class="para-desc text-muted">
            <li>Dropshipping</li>
            <li>Last Mile</li>
            <li>Fulfillment</li>
          </ul> -->
          </div>
          <a target="_blank" href="https://api.whatsapp.com/send?phone=573024144473&text=Hola%20SWAYP"
            class="btn btn-primary">Escribenos Ahora</a>
        </div>
        <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0 pb-sm-10 pb-5">
          <div class="text-md-end text-center ms-lg-4">
            <img src="../../../../assets/images/cuidamos.png" class="img-fluid" alt="">
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>

  <section class="d-table w-100" id="home">
    <div class="container" style="margin-top: 130px; margin-bottom: 100px;">
      <div class="row mt-5 align-items-center">
        <div class="col-lg-7 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0 pb-sm-10 pb-5">
          <div class="text-md-end text-center ms-lg-4">
            <img src="../../../../assets/images/process-swayp.png" class="img-fluid" alt="">
          </div>
        </div>
        <div class="col-lg-5 col-md-6">
          <div class="title-heading">
            <h1 class="heading mb-2">Nuestro proceso</h1>
            <!-- <p class="para-desc text-muted">Iniciamos en el primer trimestre del 2023.</p> -->
            <!-- <div class="mt-4">
              <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
              <a href="javascript:void(0)" class="btn btn-outline-primary mt-2"><i class="uil uil-google-play"></i> Play
                Store</a>
            </div> -->
            <p class="mt-4 para-desc text-muted">Detrás de cada entrega hay un equipo y un proceso comprometido para que
              tu pedido llegue de forma oportuna, y tu cliente sienta la mayor satisfacción del servicio. Para eso,
              implementamos un proceso limpio y confiable.
            </p>
            <!-- <ul class="para-desc text-muted">
              <li>Dropshipping</li>
              <li>Last Mile</li>
              <li>Fulfillment</li>
            </ul> -->
          </div>
          <a target="_blank" href="https://api.whatsapp.com/send?phone=573024144473&text=Hola%20SWAYP"
            class="btn btn-primary">Escribenos Ahora</a>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>

  <!-- <section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <h2 class="heading mb-4 text-center pb-2">Nuestros servicios</h2>
      <div class="col-md-3 col-12 text-center">
        <div class="card shadow rounded border-0 overflow-hidden">
          <img src="assets/images/milla.png" class="img-fluid" alt="">
          <div class="card-body">
            <h5 class="card-title">Última milla</h5>
            <p class="text-muted">Recogemos y entregamos los pedidos por ti, mejorando los tiempos de entrega y la
              experiencia de tu cliente.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12 text-center">
        <div class="card shadow rounded border-0 overflow-hidden">
          <img src="assets/images/full.png" class="img-fluid" alt="">
          <div class="card-body">
            <h5 class="card-title">Fulfillment</h5>
            <p class="text-muted">Almacenamos, empacamos y entregamos tus productos por ti, gracias a nuestra red de
              bodegas disponibles en todo el país.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12 text-center">
        <div class="card shadow rounded border-0 overflow-hidden">
          <img src="assets/images/ruta.png" class="img-fluid" alt="">
          <div class="card-body">
            <h5 class="card-title">Ruta óptima</h5>
            <p class="text-muted">Gracias a nuestro sistema de ruta óptima, permite que nuestros mensajeros mejoren sus
              tiempos de entrega, logrando la entrega oportuna de tus productos y reduciendo gastos innecesarios.</p>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12 text-center">
        <div class="card shadow rounded border-0 overflow-hidden">
          <img src="assets/images/inventario.png" class="img-fluid" alt="">
          <div class="card-body">
            <h5 class="card-title">Inventarios</h5>
            <p class="text-muted">En todo negocio es importante controlar y gestionar de forma oportuna los inventarios,
              esto te permite concentrarte en vender.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

  <section class="section background-gray pt-10 pb-10">
    <app-svgmap></app-svgmap>
  </section>

  <section class="section pb-0">
    <div class="row">
      <div class="col-12">
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-cloud" role="tabpanel" aria-labelledby="pills-cloud-tab">
            <!-- Showcase Start -->
            <section class="section pt-0">
              <div class="container">
              </div>

              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                      <h1 class="heading mb-2"><span class="text-primary">En SWAYP</span> cambiamos la
                        forma de hacer<br>última milla en el país.</h1>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center">
                  <div class=" text-center">
                    <ul ngbNav #nav="ngbNav"
                      class="nav-pills nav-justified flex-column flex-sm-row rounded col-lg-8 col-md-12 offset-md-2">
                      <li ngbNavItem>
                        <a ngbNavLink class="rounded">
                          <div class="text-center py-2">
                            <h6 class="mb-0">Soy mensajero</h6>
                          </div>
                        </a>
                        <ng-template ngbNavContent class="mt-0">
                          <div class="row align-items-center">
                            <div class="col-md-6">
                              <img src="../../../../assets/images/mensajeros.png" class="img-fluid mx-auto d-block"
                                alt="">
                            </div>
                            <div class="col-md-6 mt-2 mt-sm-0 pt-sm-0">
                              <div class="section-title text-md-start">
                                <h3 class="title subtitle mb-4"><i
                                    class="uil uil-angle-double-right text-primary"></i>Únete a nuestra red de
                                  mensajeros
                                  y transforma tu forma de hacer entregas.</h3>
                                <p class="text-muted">Conviértete en uno de nuestros mensajeros y descubre una forma
                                  moderna y potente de trabajar.</p>
                                <ul class="list-unstyled text-muted">
                                  <li class="mb-1"><span class="text-primary h5 me-2"><i
                                        class="uil uil-check-circle align-middle"></i></span>Mejores pagos gracias a
                                    nuestro sistema de kilometraje.</li>
                                  <li class="mb-1"><span class="text-primary h5 me-2"><i
                                        class="uil uil-check-circle align-middle"></i></span>Nuestro sistema calcula la
                                    mejor ruta por ti.</li>
                                  <li class="mb-1"><span class="text-primary h5 me-2"><i
                                        class="uil uil-check-circle align-middle"></i></span>Mejora tu eficiencia
                                    gracias
                                    a nuestra comunicación interna.</li>
                                </ul>
                                <div class="col-md-6">
                                  <a routerLink="/team-delivery"
                                    class="btn-full btn btn-primary text-center">Inscribirme
                                    Ahora</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </li>

                      <li ngbNavItem>
                        <a ngbNavLink class="rounded">
                          <div class="text-center py-2">
                            <h6 class="mb-0">Soy un comercio</h6>
                          </div>
                        </a>
                        <ng-template ngbNavContent>
                          <div class="row align-items-center">
                            <div class="col-md-6">
                              <img src="../../../../assets/images/clientes.png" class="img-fluid mx-auto d-block"
                                alt="">
                            </div>

                            <div class="col-md-6 mt-2 mt-sm-0 pt-sm-0">
                              <div class="section-title text-md-start">
                                <h3 class="title subtitle mb-4"><i
                                    class="uil uil-angle-double-right text-primary"></i>Tu
                                  logística en las manos correctas.</h3>
                                <p class="text-muted">Transformamos la forma de entregar tus pedidos, innovamos para que
                                  pases al siguiente nivel con tu logística.</p>
                                <ul class="list-unstyled text-muted">
                                  <li class="mb-1"><span class="text-primary h5 me-2"><i
                                        class="uil uil-check-circle align-middle"></i></span>Entregas oportunas
                                    mejorando
                                    la experciencia de tu cliente.</li>
                                  <li class="mb-1"><span class="text-primary h5 me-2"><i
                                        class="uil uil-check-circle align-middle"></i></span>Un sistema potente y
                                    eficiente con una interfaz fácil de usar.</li>
                                  <li class="mb-1"><span class="text-primary h5 me-2"><i
                                        class="uil uil-check-circle align-middle"></i></span>Automatizamos y nos
                                    integramos a disferentes plataformas del mercado.</li>
                                  <li class="mb-1"><span class="text-primary h5 me-2"><i
                                        class="uil uil-check-circle align-middle"></i></span>Controla tus gastos,
                                    inventarío y entregas en 40 zonas del país.</li>
                                </ul>
                                <div class="col-md-6">
                                  <a routerLink="/team-clients" class="btn-full btn btn-primary text-center">Solicitar
                                    una
                                    Asesoría</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="col-12 mt-4 pt-2"></div>
                  </div>
                </div>
              </div>
            </section>
            <!--end section-->
          </div>
        </div><!--end tab content-->
      </div><!--end col-->
    </div><!--end row-->
  </section>

  <section class="d-table w-100" id="home">
    <div class="container" style="margin-top: 130px; margin-bottom: 100px;">
      <div class="col-12 text-center">
        <div class="section-title">
          <h2 class="heading">Automatiza tu e-commerce con nuestra integración</h2>
          <p class="text-center mt-15">Nos encargamos de hacer el trabajo pesado por ti, nos integramos con la mayoría
            de plataformas para que montar tus pedidos<br>no sea un dolor de cabeza.
            Podrás aprobar tus pedidos con solo hacer clic en un botón.</p>
        </div>
      </div>
      <div class="row" style="margin-top: 45px;">
        <div class="col-4 col-md-4 image-container2">
          <img src="assets/images/woocommerce.jpg">
        </div>
        <div class="col-4 col-md-4 image-container2">
          <img src="assets/images/shopi.png">
        </div>
        <div class="col-4 col-md-4 image-container2">
          <img src="assets/images/3pod.png">
        </div>
      </div>
      <div class="row">
        <div class="col-4 col-md-4 image-container">
          <img src="assets/images/clickandchill.png">
        </div>
        <div class="col-4 col-md-4 image-container">
          <img src="assets/images/funnellflex.png">
        </div>
        <div class="col-4 col-md-4 image-container">
          <img src="assets/images/funnelish.png">
        </div>
      </div>
    </div>
  </section>
  <section class="d-table w-100 background-gray" style="padding-top: 35px; padding-bottom: 35px;" id="home">
    <div class="container" style="margin-top: 35px; margin-bottom: 100px;">
      <div class="col-12 text-center">
        <div class="section-title">
          <h2 class="heading">Nuestros aliados</h2>
        </div>
      </div>
      <div class="row" style="margin-top: 45px;align-items: center;">
        <div class="col-sm-6 col-md-4 text-center">
          <img src="assets/images/logo-u.png" height="50px">
        </div>
        <!-- <div class="col-sm-6 col-md-3 text-center">
          <img src="assets/images/Logo-Efficommerce-H.png" height="50px">
        </div> -->
        <div class="col-sm-6 col-md-4 text-center">
          <img src="assets/images/hoko.png" height="50px">
        </div>
        <div class="col-sm-6 col-md-4 text-center">
          <img src="assets/images/mastershop-logo-black.png" height="30px">
        </div>
      </div>
    </div>
  </section>

  <section class="section pt-10 pb-10">
    <div class="row">
      <div class="col-3"></div>
      <div class="col-12 col-md-6">
        <h2 class="heading text-center padding-pb-25">Preguntas frecuentes</h2>
        <div class="accordion  pt-30" id="buyingquestion">
          <div class="accordion-item rounded">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button border-0 bg-light" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                ¿Que es última milla?
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse border-0 collapse show" aria-labelledby="headingOne"
              data-bs-parent="#buyingquestion">
              <div class="accordion-body text-muted">
                Cuando hablamos de última milla nos referimos a los últimos tramos o kilométricos que le falta a un
                pedido
                para llegar al destinatario final.
              </div>
            </div>
          </div>

          <div class="accordion-item rounded mt-2">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                ¿Cuál es el tiempo promedio de entrega de pedidos?
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse border-0 collapse" aria-labelledby="headingThree"
              data-bs-parent="#buyingquestion">
              <div class="accordion-body text-muted">
                En SWAYP nos esmeramos para que el tiempo de entrega no sea mayor a 24 horas hábiles.
              </div>
            </div>
          </div>

          <div class="accordion-item rounded mt-2">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                ¿Cuál es la cobertura de SWAYP?
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse border-0 collapse" aria-labelledby="headingFour"
              data-bs-parent="#buyingquestion">
              <div class="accordion-body text-muted">
                En SWAYP cubrimos más del 80% del territorio nacional con expansión constante. Adicionalmente, estamos
                próximos a tener presencia en países de Latinoamérica como Ecuador, Costa Rica, Brasil, entre otros.
              </div>
            </div>
          </div>

          <div class="accordion-item rounded mt-2">
            <h2 class="accordion-header" id="headingFive">
              <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                ¿Con cuáles plataformas se integra SWAYP?
              </button>
            </h2>
            <div id="collapseFive" class="accordion-collapse border-0 collapse" aria-labelledby="headingFive"
              data-bs-parent="#buyingquestion">
              <div class="accordion-body text-muted">
                Tenemos capacidad de integrarnos con cualquier tipo de plataforma que, mediante API, nos permita recibir
                pedidos. Actualmente, estamos integrados con plataformas como Shopify, WooCommerce, WordPress, 3POD,
                entre otras.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2"></div>
    </div>
  </section>


  <!-- Shape Start -->
  <!-- <div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div> -->
  <!--Shape End-->

  <!-- Testi n Download cta start -->
  <!-- <section class="section pt-0">
  <div class="container">

    <div class="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Aplica YA como mensajero! </h4>
          <p class="text-muted para-desc mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate awareness, drive traffic, connect.</p>
            <div class="mt-4">
              <a href="javascript:void(0)">
                  <img src="assets/images/app/app.png" class="m-1" height="50" alt="">
              </a>
              <a href="javascript:void(0)">
                  <img src="assets/images/app/playstore.png" class="m-1" height="50" alt="">
              </a>
          </div> 
        </div>
      </div>
    </div>
  </div>
</section> -->
  <!--end section-->
  <!-- Testi n Download cta End -->

  <section class="section-animation">
    <div class="wrapper-animate-image" style="background-image: url('assets/images/gif/animacion-registro2.gif');">
    </div>
  </section>
  <!-- Shape Start -->
  <div class="position-relative">
    <div class="shape overflow-hidden text-footer">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--Shape End-->


  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->