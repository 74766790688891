import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.css']
})

/**
 * PAge Error Component
 */
export class PageErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
